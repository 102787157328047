//
// Contacts.scss
//

.contact-links {
	a {
		color: $body-color;
	}
}

// profile

.profile-user-wid {
	margin-top: -26px;
}
