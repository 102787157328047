.account-pages {
	height: 100vh;
	display: grid;
	place-items: center;
	.logo-bar{
		padding: 1rem 1.75rem 0 1.75rem;
	}
	// auth-pass-inputgroup
	.auth-pass-inputgroup {
		input[type='input'] + .btn .mdi-eye-outline {
			&:before {
				content: '\F06D1';
			}
		}
	}

	// authentication full page

	.auth-full-bg {
		background-color: rgba($primary, 0.25);
		display: flex;

		@media (min-width: 1200px) {
			height: 100vh;
		}

		&::before {
			content: '';
			position: absolute;
			width: 300px;
			height: 300px;
			border-radius: 50%;
		}

		.bg-overlay {
			background: url('../../../images/bg-auth-overlay.png');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	.auth-full-page-content {
		display: flex;

		@media (min-width: 1200px) {
			min-height: 100vh;
		}
	}
}
