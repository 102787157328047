.dropdown-item {
	display: flex;
	align-items: center;
	gap: 0.25rem;
	cursor: pointer;
	i {
		margin-right: 0;
	}
}
.page-content {
	position: relative;
	.page-title {
		margin-bottom: 24px;
	}
}
.dataTablesLists {
	padding-bottom: 9rem;
	.sortable {
		cursor: pointer;
	}
	table.dataTable thead > tr > th.sorting_asc,
	table.dataTable thead > tr > th.sorting_desc,
	table.dataTable thead > tr > th.sorting,
	table.dataTable thead > tr > td.sorting_asc,
	table.dataTable thead > tr > td.sorting_desc,
	table.dataTable thead > tr > td.sorting {
		padding-right: 30px;
	}
	table.dataTable thead > tr > th:active,
	table.dataTable thead > tr > td:active {
		outline: none;
	}
	table.dataTable thead .sorting,
	table.dataTable thead .sorting_asc,
	table.dataTable thead .sorting_desc,
	table.dataTable thead .sorting_asc_disabled,
	table.dataTable thead .sorting_desc_disabled {
		cursor: pointer;
		position: relative;
	}
	table.dataTable thead .sorting:before,
	table.dataTable thead .sorting:after,
	table.dataTable thead .sorting_asc:before,
	table.dataTable thead .sorting_asc:after,
	table.dataTable thead .sorting_desc:before,
	table.dataTable thead .sorting_desc:after,
	table.dataTable thead .sorting_asc_disabled:before,
	table.dataTable thead .sorting_asc_disabled:after,
	table.dataTable thead .sorting_desc_disabled:before,
	table.dataTable thead .sorting_desc_disabled:after {
		position: absolute;
		bottom: 0.9em;
		display: block;
		opacity: 0.3;
	}
	table.dataTable thead .sorting:before,
	table.dataTable thead .sorting_asc:before,
	table.dataTable thead .sorting_desc:before,
	table.dataTable thead .sorting_asc_disabled:before,
	table.dataTable thead .sorting_desc_disabled:before {
		right: 1em;
		content: '\2191';
	}
	table.dataTable thead .sorting:after,
	table.dataTable thead .sorting_asc:after,
	table.dataTable thead .sorting_desc:after,
	table.dataTable thead .sorting_asc_disabled:after,
	table.dataTable thead .sorting_desc_disabled:after {
		right: 0.5em;
		content: '\2193';
	}
	table.dataTable thead .sorting_asc:before,
	table.dataTable thead .sorting_desc:after {
		opacity: 1;
	}
	table.dataTable thead .sorting_asc_disabled:before,
	table.dataTable thead .sorting_desc_disabled:after {
		opacity: 0;
	}
	div.dataTables_scrollHead table.dataTable {
		margin-bottom: 0 !important;
	}
	div.dataTables_scrollBody table {
		border-top: none;
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	div.dataTables_scrollBody table thead .sorting:before,
	div.dataTables_scrollBody table thead .sorting_asc:before,
	div.dataTables_scrollBody table thead .sorting_desc:before,
	div.dataTables_scrollBody table thead .sorting:after,
	div.dataTables_scrollBody table thead .sorting_asc:after,
	div.dataTables_scrollBody table thead .sorting_desc:after {
		display: none;
	}
	div.dataTables_scrollBody table tbody tr:first-child th,
	div.dataTables_scrollBody table tbody tr:first-child td {
		border-top: none;
	}
	div.dataTables_scrollFoot > .dataTables_scrollFootInner {
		box-sizing: content-box;
	}
	div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
		margin-top: 0 !important;
		border-top: none;
	}
	@media screen and (max-width: 767px) {
		div.dataTables_wrapper div.dataTables_length,
		div.dataTables_wrapper div.dataTables_filter,
		div.dataTables_wrapper div.dataTables_info,
		div.dataTables_wrapper div.dataTables_paginate {
			text-align: center;
		}
	}
	table.dataTable.table-sm > thead > tr > th {
		padding-right: 20px;
	}
	table.dataTable.table-sm .sorting:before,
	table.dataTable.table-sm .sorting_asc:before,
	table.dataTable.table-sm .sorting_desc:before {
		top: 5px;
		right: 0.85em;
	}
	table.dataTable.table-sm .sorting:after,
	table.dataTable.table-sm .sorting_asc:after,
	table.dataTable.table-sm .sorting_desc:after {
		top: 5px;
	}
	table.table-bordered.dataTable th,
	table.table-bordered.dataTable td {
		border-left-width: 0;
	}
	table.table-bordered.dataTable th:last-child,
	table.table-bordered.dataTable th:last-child,
	table.table-bordered.dataTable td:last-child,
	table.table-bordered.dataTable td:last-child {
		border-right-width: 0;
	}
	table.table-bordered.dataTable tbody th,
	table.table-bordered.dataTable tbody td {
		border-bottom-width: 0;
	}
	div.dataTables_scrollHead table.table-bordered {
		border-bottom-width: 0;
	}
	div.table-responsive > div.dataTables_wrapper > div.row {
		margin: 0;
	}
	div.table-responsive
		> div.dataTables_wrapper
		> div.row
		> div[class^='col-']:first-child {
		padding-left: 0;
	}
	div.table-responsive
		> div.dataTables_wrapper
		> div.row
		> div[class^='col-']:last-child {
		padding-right: 0;
	}
	tbody tr {
		// cursor: pointer;
	}
	.dropup > .caret {
		margin: 10px 0;
		color: #cccccc;
	}
	.dropup .caret,
	.navbar-fixed-bottom .dropdown .caret {
		content: '';
		border-top: 0;
		border-bottom: 4px dashed;
	}
	.react-bootstrap-table th > .react-bootstrap-table-sort-order > .caret {
		margin: 10px 6.5px;
	}
	.caret {
		display: inline-block;
		width: 0;
		height: 0;
		margin-left: 2px;
		vertical-align: middle;
		border-top: 4px dashed;
		border-top: 4px solid\9;
		border-right: 4px solid transparent;
		border-left: 4px solid transparent;
	}
	.dropdown-menu:not(.dropdown-menu-end) {
		min-width: unset;
		.dropdown-item {
			padding: 0;
		}
		a {
			width: 100% !important;
			padding: 0.4rem 1.1rem;
		}
	}
	.order {
		cursor: pointer;
	}
	.react-bootstrap-table-sort-order {
		cursor: pointer;
	}
	.selectedRow {
		background-color: $blueGrey50;
	}
	.selection-cell,
	.selection-cell-header {
		cursor: pointer;
	}
	.pagination {
		margin-bottom: 0;
	}
}
@keyframes scale {
	100% {
		transform: scale(0.9);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
@keyframes fadeAway {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}
@keyframes modalSlideIn {
	0% {
		transform: translateY(-50%);
	}

	100% {
		transform: translateY(0);
	}
}
@keyframes modalSlideOut {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-50%);
	}
}
.ck-editor__editable {
	min-height: 10rem;
}
.dangerouslySetInnerHTML {
	* {
		margin: 0;
		padding: 0;
	}
}
.groups-page {
	.nav-justified > .nav-link,
	.nav-justified .nav-item {
		flex-grow: unset;
		.active {
			background: transparent;
		}
	}
}

input[type='checkbox'] {
	cursor: pointer;
}
.add-user-row {
	padding: 1rem 1rem 0;
	border-radius: $border-radius;
	&:nth-of-type(odd) {
		background-color: $blueGrey50;
	}
}
.fit-content {
	width: fit-content;
}
.select2-selection__menu {
	z-index: 5 !important;
}
.react-bootstrap-table-editing-cell {
	display: table-cell !important;
	max-width: 1px !important;
}
.icon-copy {
	cursor: pointer;
	color: $primary;
}
td,
th {
	vertical-align: middle;
}
input[type='number']:read-only {
	-moz-appearance: textfield;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
.input-incremented {
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	button {
		min-height: 100%;
	}
	input[type='number'] {
		-moz-appearance: textfield;
	}
	.decor-text {
		min-height: 100%;
	}
}
.gap {
	&-1 {
		gap: 0.5rem;
	}
	&-2 {
		gap: 1rem;
	}
	&-3 {
		gap: 1.5rem;
	}
	&-4 {
		gap: 2rem;
	}
	&-5 {
		gap: 3rem;
	}
}

.event-timeline-dot {
	line-height: 0;
}
.fade-in {
	animation: fadeIn $transition;
}
.hidden-element {
	visibility: hidden;
	height: 0;
	width: 0;
	overflow: hidden;
	position: fixed;
}
.header-with-badge {
	display: flex;
	gap: 0.5rem;
	span {
		padding: 0.2em 0.6em;
		line-height: 12px;
	}
}
.popover {
	width: fit-content !important;
	max-width: unset;
}
.input-incremented {
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	.group-text {
		min-height: 100%;
	}
	button {
		min-height: 100%;
	}
	input[type='number'] {
		-moz-appearance: textfield;
		text-align: end;
	}
}
.table-responsive {
	overflow-y: clip;
}
.search-box {
	.form-control {
		border-radius: 30px;
		padding-left: 40px;
	}
	.search-icon {
		font-size: 16px;
		position: absolute;
		left: 13px;
		top: 0;
		line-height: 38px;
	}
}
.cursor-pointer {
	cursor: pointer !important;
}
