//
// preloader.scss
//

#preloader {
	width: 100%;
	height: 100vh;
	padding-top: $header-height;
	position: relative;
}

#status:not(select) {
	width: 40px;
	height: 40px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.spinner-chase {
	margin: 0 auto;
	width: 40px;
	height: 40px;
	position: relative;
	animation: spinner-chase 2.5s infinite linear both;
}

.chase-dot {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	animation: chase-dot 2s infinite ease-in-out both;
	&:before {
		content: '';
		display: block;
		width: 25%;
		height: 25%;
		background-color: $primary;
		border-radius: 100%;
		animation: chase-dot-before 2s infinite ease-in-out both;
	}

	&:nth-child(1) {
		animation-delay: -1.1s;
		&:before {
			animation-delay: -1.1s;
		}
	}
	&:nth-child(2) {
		animation-delay: -1s;
		&:before {
			animation-delay: -1s;
		}
	}
	&:nth-child(3) {
		animation-delay: -0.9s;
		&:before {
			animation-delay: -0.9s;
		}
	}
	&:nth-child(4) {
		animation-delay: -0.8s;
		&:before {
			animation-delay: -0.8s;
		}
	}
	&:nth-child(5) {
		animation-delay: -0.7s;
		&:before {
			animation-delay: -0.7s;
		}
	}
	&:nth-child(6) {
		animation-delay: -0.6s;
		&:before {
			animation-delay: -0.6s;
		}
	}
}

@keyframes spinner-chase {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes chase-dot {
	80%,
	100% {
		transform: rotate(360deg);
	}
}

@keyframes chase-dot-before {
	50% {
		transform: scale(0.4);
	}
	100%,
	0% {
		transform: scale(1);
	}
}
